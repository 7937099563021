import React from 'react';


export default function Footer() {
  return (
  <div className='footer'>
      <p>2024 &copy; MHE Movers</p>
  </div>
  )
}
