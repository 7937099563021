import i1 from "./Product images/0001.jpg"
import i2 from "./Product images/0002.jpg"
import i3 from "./Product images/0003.jpg"
import i4 from "./Product images/0004.jpg"
import i5 from "./Product images/0005.jpg"
import i6 from "./Product images/0006.jpg"
import i7 from "./Product images/0007.jpg"
import i8 from "./Product images/0008.jpg"
import i9 from "./Product images/0009.jpg"
//pallet trucks
import i11 from "./Product images/i11.jpg"
import i10 from "./Product images/i10.png"
import i12 from "./Product images/i12.webp"
import i13 from "./Product images/i13.jpg"

// #Platform Trolleys
 import i14 from "./Product images/i14.jpg"
 import i15 from "./Product images/i15.jpg"
 import i16 from "./Product images/i16.png"
 import i17 from "./Product images/i17.jpg"
 import i18 from "./Product images/i18.jpg"

// # Goods Lift
 import i19 from "./Product images/i19.jpg"
 import i20 from "./Product images/i20.jpg"
 import i21 from "./Product images/i21.jpg"
 import i22 from "./Product images/i22.jpg"
 import i23 from "./Product images/i23.jpg"
 import i24 from "./Product images/i24.jpg"
 import i25 from "./Product images/i25.png"
 import i26 from "./Product images/i26.jpg"
 import i27 from "./Product images/i27.png"
 import i28 from "./Product images/i28.jpg"

 

 import i29 from "./Product images/i29.jpg"
 import i30 from "./Product images/i30.webp"
 import i31 from "./Product images/i31.jpg"
 import i32 from "./Product images/i32.jpg"
 import i33 from "./Product images/i33.jpg"
 import i34 from "./Product images/i34.jpg"
 import i35 from "./Product images/i35.jpg"
 import i36 from "./Product images/i36.png"


 //#Dock Levellers
 import i37 from "./Product images/i37.jpg"
 import i38 from "./Product images/i38.jpg"

//#Hydraulic Floor Crane
import i39 from "./Product images/i39.jpg"


export let IndustrialStakers =
[{
    Image:i1,
    BrandName:"MHE Movers",
    Name:"Battery Operated Stacker",
    Description:"Battery Operated Stackers (BOS) are electric material handling machines powered by rechargeable batteries. They lift, move, and stack pallets or goods in warehouses and industrial settings.",
    Applications:"Efficient stock movement within warehouses.Facilitating inventory replenishment.Assisting in order picking processes.Conducting cycle counting activities.Loading and unloading trucks or storage areas.",
    Color:"Red/Yellow",
    Capacity:"1-3tons",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"Hydraulic",
    Warranty:"1 Year",
    ForkLenght:"1150 mm",
    ForkWidth:"",
    RatedLoad:"Up to 1.5 Tons",
    Type:"Battery Operated",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"",
    Shape:"",
    Style:"Flat",
},
 {
    Image:i2,
    BrandName:"MHE Movers",
    Name:"Counter Balance Stacker",
    Description:"A Counter Balance Stacker is a type of forklift designed with a counterweight at the rear to stabilize loads, eliminating the need for outriggers. This design allows for direct access to pallets from the ground level, making it ideal for indoor use and tight spaces.",
Applications:
"Warehousing and Distribution: Ideal for loading and unloading pallets, stacking goods in racks, and transporting materials within warehouses and distribution centers.Manufacturing: Used for moving raw materials, components, and finished products between production areas in manufacturing facilities.Retail: Suitable for stocking shelves, handling goods in storage areas, and replenishing inventory in retail stores and supermarkets.Logistics: Effective for loading and unloading trucks, trailers, and containers, as well as transporting goods within logistics hubs and ports.Construction: Useful for transporting materials around construction sites, including bricks, blocks, and bags of cement, contributing to efficient construction operations.",
    Color:"Red/Yellow",
    Capacity:"1-3tons",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"680 mm",
    RatedLoad:"Up to 1.5 Tons",
    Type:"Battery Operated",
    BodyMaterail:"Mild Steel",
    Power:"",
    Voltage:"",
    Material:"",
    Shape:"",
    Style:"",
},
 {
    Image:i3,
    BrandName:"MHE Movers",
    Name:"Electric Pallet Stacker",
    Description:"An Electric Pallet Stacker is a versatile material handling equipment powered by electricity, designed for lifting and transporting palletized goods. It features an electric motor for effortless operation and is suitable for indoor use due to its zero-emission operation.",
Applications:
"Warehousing and Distribution: Used for loading and unloading pallets, organizing goods in racks, and transporting materials within warehouses and distribution centers.Manufacturing: Ideal for moving raw materials, work-in-progress items, and finished products between different areas of production in manufacturing facilities.Retail: Suitable for stocking shelves, handling merchandise in storage areas, and replenishing inventory in retail stores and supermarkets.Logistics: Effective for moving goods within logistics facilities, loading and unloading trucks, trailers, and containers, and facilitating order fulfillment processes.Cold Storage: Adapted versions are used in cold storage environments, such as food and pharmaceutical warehouses, for handling temperature-sensitive products efficiently.Custom Applications: Can be customized for specialized tasks, such as handling oversized or irregularly shaped loads, operating in confined spaces, or navigating rough terrain in outdoor settings.",
    Color:"Red/Yellow",
    Capacity:"1.5",
    LiftingCapacity:"2.5 meter",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"550mm & 750mm",
    RatedLoad:"",
    Type:"Fully Electric",
    BodyMaterail:"",
    Power:"",
    Voltage:"220v",
    Material:"",
    Shape:"",
    Style:"",
},
 {
    Image:i4,
    BrandName:"MHE Movers",
    Name:"Fork Manual Stacker",
    Description:"An Electric Pallet Stacker is a versatile material handling equipment powered by electricity, designed for lifting and transporting palletized goods. It features an electric motor for effortless operation and is suitable for indoor use due to its zero-emission operation.",
Applications:
"Warehousing and Distribution: Used for loading and unloading pallets, organizing goods in racks, and transporting materials within warehouses and distribution centers.Manufacturing: Ideal for moving raw materials, work-in-progress items, and finished products between different areas of production in manufacturing facilities.Retail: Suitable for stocking shelves, handling merchandise in storage areas, and replenishing inventory in retail stores and supermarkets.Logistics: Effective for moving goods within logistics facilities, loading and unloading trucks, trailers, and containers, and facilitating order fulfillment processes.Cold Storage: Adapted versions are used in cold storage environments, such as food and pharmaceutical warehouses, for handling temperature-sensitive products efficiently.Custom Applications: Can be customized for specialized tasks, such as handling oversized or irregularly shaped loads, operating in confined spaces, or navigating rough terrain in outdoor settings.",
    Color:"Red/Yellow",
    Capacity:"500kg",
    LiftingCapacity:"500-750mm",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"900mm & 1150mm",
    ForkWidth:"550mm & 750mm",
    RatedLoad:"",
    Type:"Manual",
    BodyMaterail:"",
    Power:"Battery",
    Voltage:"",
    Material:"",
    Shape:"Rectangle",
    Style:"",
},
{
    Image:i5,
    BrandName:"MHE Movers",
    Name:"Fully Electric Stacker",
    Description:"A Fully Electric Stacker is a type of material handling equipment powered entirely by electricity, including both lifting and propulsion systems. It does not require manual pumping or human effort for operation. These stackers are equipped with electric motors for lifting, lowering, and moving loads, providing efficient and ergonomic handling of pallets or goods.",
Applications:
"Warehousing and Distribution: Ideal for loading and unloading pallets, stacking goods in high racks, and transporting materials within warehouses and distribution centers.Manufacturing: Suitable for moving raw materials, work-in-progress items, and finished products between different areas of production in manufacturing facilities, contributing to streamlined production processes.Retail: Used for stocking shelves, organizing merchandise in storage areas, and replenishing inventory in retail stores and supermarkets, enhancing efficiency and productivity in retail operations.Logistics: Effective for moving goods within logistics facilities, loading and unloading trucks, trailers, and containers, and facilitating order fulfillment processes in distribution centers and logistics hubs.Cold Storage: Adapted versions are available for use in cold storage environments, such as food and pharmaceutical warehouses, where temperature-sensitive products need to be handled efficiently and safely.High-Demand Environments: Suited for environments with high demand for material handling tasks, where the continuous operation and reliability of electric-powered equipment are essential for meeting productivity goals.Ergonomic Handling: Provides ergonomic benefits for operators by eliminating manual effort required for lifting and lowering loads, reducing the risk of injuries and fatigue associated with manual handling tasks.",
    Color:"Red/Yellow",
    Capacity:"1500kg",
    LiftingCapacity:"2.5 meter",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"Fully Electric",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"",
    Shape:"",
    Style:"",
},{
 Image:i6,
    BrandName:"MHE Movers",
    Name:"Hand Pallet Stacker",
    Description:"A Hand Pallet Stacker, also known as a Manual Pallet Stacker, is a simple and versatile material handling device operated by hand. It features forks for lifting pallets or goods and a manual pump mechanism for raising and lowering loads. It is designed for lifting and transporting pallets in environments where electricity is not available or where manual operation is preferred.",
Applications:
"Warehousing and Distribution: Used for lifting and moving pallets, stacking goods in racks, and organizing inventory within warehouses and distribution centers, especially in areas without access to electricity.Retail: Suitable for stocking shelves, handling merchandise in storage areas, and transporting goods within retail stores and warehouses, providing a cost-effective solution for small-scale operations.Manufacturing: Ideal for handling materials, components, and products in production areas where electrically powered equipment may not be suitable or where occasional lifting tasks are required.Small Businesses: Cost-effective solution for small businesses with limited material handling needs, providing flexibility and ease of use without the need for electric power.Low-Volume Environments: Used in environments with low-volume lifting and stacking tasks, where the simplicity and affordability of manual operation meet the operational requirements effectively.Outdoor Applications: Suitable for outdoor applications where electricity may not be available or practical, such as construction sites, agricultural facilities, or temporary storage areas.Emergency Situations: Used as backup equipment or in emergency situations where electricity is unavailable or when other powered equipment is not operational, providing a reliable solution for lifting and moving pallets.",
    Color:"Red/Yellow",
    Capacity:"1500kg",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"Manual",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"",
    Shape:"",
    Style:"",
},
{ Image:i7,
    BrandName:"MHE Movers",
    Name:"Heavy Duty Stacker",
    Description:"Robust material handling equipment designed for lifting and transporting heavy loads in industrial environments. Built with reinforced components and high load capacity for reliable performance.",
Applications:
"Heavy Industry: Manufacturing plants, foundries, steel mills.Warehousing: Handling oversized or heavy palletized goods.Construction: Lifting and positioning heavy building materials.Automotive: Handling large components and vehicle parts.Ports: Loading and unloading heavy cargo.Aerospace: Moving aircraft components during assemblyMining: Transporting bulk materials and minerals.Utilities: Handling heavy equipment in power plants.",
    Color:"Red/Yellow",
    Capacity:"2000kg",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"",
    Shape:"",
    Style:"",
},
  {
    Image:i8,
    BrandName:"MHE Movers",
    Name:"Hydraulic Stacker",
    Description:"A Hydraulic Stacker is a type of material handling equipment powered by hydraulic systems for lifting and lowering loads. It typically features forks or platforms for handling pallets or goods, with hydraulic cylinders providing the lifting force. Hydraulic stackers are versatile and offer precise control over lifting heights and speeds.",
    Applications:"Warehousing and Distribution: Used for loading and unloading pallets, stacking goods in racks, and transporting materials within warehouses and distribution centers.Manufacturing: Ideal for moving raw materials, work-in-progress items, and finished products between different areas of production in manufacturing facilities.Retail: Suitable for stocking shelves, handling merchandise in storage areas, and replenishing inventory in retail stores and supermarkets.Logistics: Effective for moving goods within logistics facilities, loading and unloading trucks, trailers, and containers, and facilitating order fulfillment processes.Construction: Used for lifting and positioning heavy building materials such as bricks, blocks, and bags of cement on construction sites.Maintenance: Handy for equipment maintenance tasks, enabling workers to lift heavy machinery parts and components for repairs.Assembly: Utilized in assembly lines for positioning components and products at ergonomic heights for workers.",
    Color:"Red/Yellow",
    Capacity:"200-250kg",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"Hydraulic",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"Portable",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"",
    Shape:"",
    Style:"",
},
{
 Image:i9,
    BrandName:"MHE Movers",
    Name:"Mini Platform Stacker",
    Description:"Compact and versatile equipment designed for lifting and moving small loads in confined spaces. Features a platform or forks with a small footprint for maneuverability.",
    Applications: "Widely used in retail, warehousing, workshops, offices, schools, and hospitality industries for efficient handling of light loads in tight spaces.",
    Application:"Industrial, Lifting Goods",
    Color:"Red/Yellow",
    Capacity:"500kg",
    LiftingCapacity:"550*750mm",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"",
    Shape:"",
    Style:"",
},
 ]
 

 const palletTrucks =[{
    Image:i10,
    BrandName:"MHE Movers",
    Name:"Battery Operated Pallet Truck",
    Description:"A battery-operated pallet truck is a material handling equipment used for moving palletized loads within warehouses, distribution centers, and manufacturing facilities. It consists of a powered platform with forks to lift and transport pallets.",
    Applications:"Battery-operated pallet trucks are used in warehouses, distribution centers, and manufacturing facilities for moving palletized loads efficiently. They are electrically powered, offering maneuverability in confined spaces and capable of handling various load capacities. These trucks streamline operations in industries such as retail, logistics, manufacturing, and wholesale distribution, enhancing productivity and minimizing manual labor.",
    Color:"Red/Yellow",
    Capacity:"1-3tons",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"Battery Operated",
    Warranty:"1 Year",
    ForkLenght:" mm",
    ForkWidth:"",
    RatedLoad:"",
    Type:"Battery Operated",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"",
    Shape:"",
    Style:"",
},
{
    Image:i11,
    BrandName:"MHE Movers",
    Name:"Hand Operated Pallet Truck",
    Description:"A hand-operated pallet truck is a manually controlled material handling device designed for moving palletized loads. It typically consists of a wheeled platform with forks to lift and transport pallets. These trucks are operated by pushing or pulling a handle, which activates hydraulic mechanisms to lift and lower loads.",
    Applications:"Hand-operated pallet trucks are commonly used in various industries such as warehouses, distribution centers, and manufacturing facilities for moving palletized loads efficiently over short distances. They are also utilized in retail stores, grocery stores, and delivery operations for tasks like restocking shelves, loading and unloading trucks, and organizing inventory. These trucks offer a manual alternative for material handling in environments where space is limited or where powered equipment may not be practical or available.",
    Color:"Red/Yellow",
    Capacity:"1-3tons",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"Hydraulic",
    Warranty:"1 Year",
    ForkLenght:"1150 mm",
    ForkWidth:"",
    RatedLoad:"",
    Type:"manual",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"",
    Shape:"",
    Style:"",
},

{
    Image:i12,
    BrandName:"MHE Movers",
    Name:"High Scissor Pallet Truck",
    Description:"A high scissor pallet truck is a specialized material handling equipment designed to lift pallets to higher elevations. It features a scissor lift mechanism integrated into a pallet truck, allowing it to raise pallets to heights beyond typical pallet truck capabilities. These trucks are commonly used in warehouses, distribution centers, and retail environments for various lifting and positioning tasks.",
    Applications:"High scissor pallet trucks are utilized for tasks including loading and unloading from elevated surfaces, shelving in retail and distribution settings, feeding production lines, enabling ergonomic material handling, and optimizing space utilization across different industries.",
    Color:"Red/Yellow",
    Capacity:"1 tons",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"Electric",
    Warranty:"1 Year",
    ForkLenght:"1150-1250 mm",
    ForkWidth:"550 -685 mm",
    RatedLoad:"",
    Type:" ",
    BodyMaterail:"",
    Power:"electric",
    Voltage:"",
    Material:"",
    Shape:"",
    Style:"",
},
{
    Image:i13,
    BrandName:"MHE Movers",
    Name:"Stainless Steel Pallet Truck ",
    Description:"A stainless steel pallet truck is a specialized material handling equipment designed for the transportation and lifting of palletized loads in environments where corrosion resistance and hygiene are paramount. These trucks are constructed using stainless steel components, making them suitable for use in industries such as food processing, pharmaceuticals, and chemical manufacturing.",
    Applications:"Stainless steel pallet trucks are commonly used in industries like food processing, pharmaceuticals, and chemical manufacturing for handling palletized loads in environments requiring corrosion resistance and hygiene.",
    Color:"Grey",
    Capacity:"1-3tons",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"Hydraulic",
    Warranty:"1 Year",
    ForkLenght:"1150 mm",
    ForkWidth:"550mm",
    RatedLoad:"Up to 1.5 Tons",
    Type:"hydraulic",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"",
    Shape:"",
    Style:"",
},
 ]
 

 const PlatformTrolleys =[{
    Image:i14,
    BrandName:"MHE Movers",
    Name:"Flat Platform Trolley",
    Description:"A flat platform trolley is a versatile material handling tool with a flat surface designed to transport various goods and materials across short distances. These trolleys typically feature a sturdy platform mounted on wheels, facilitating smooth movement and maneuverability in warehouses, retail stores, and industrial settings.",
    Applications:"Flat platform trolleys are widely used for transporting goods within warehouses, distribution centers, and retail stores, as well as for moving heavy items in workshops, factories, and construction sites. They are also utilized in airports, train stations, shipping terminals, and for event setup and breakdown purposes.",
    Color:"Red/Yellow/Blue",
    Capacity:"250-800 Kg",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"Stainless Steel",
    Power:"",
    Voltage:"",
    Material:"",
    Shape:"",
    Style:"",
},
 {
    Image:i15,
    BrandName:"MHE Movers",
    Name:"Foldable Platform Trolley ",
    Description:"A foldable platform trolley is a versatile material handling tool designed for transporting goods and materials over short distances. It features a flat platform mounted on wheels and is designed to be collapsible, allowing for easy storage and transportation when not in use.",
    Applications:"Foldable platform trolleys are utilized for transporting goods within warehouses, distribution centers, and retail stores, as well as aiding in restocking shelves, moving merchandise, handling luggage and cargo in airports and train stations, supporting event setup and breakdown, and facilitating the movement of tools and materials in workshops, factories, and construction sites.",
    Color:"Red/Yellow/Blue/Grey",
    Capacity:"200-300kg",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"Foldable",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"",
    Shape:"",
    Style:"Flat",
},
 {
    Image:i16,
    BrandName:"MHE Movers",
    Name:"Hand Cart Platform Trolley",
    Description:"A hand cart platform trolley is a versatile material handling tool equipped with a platform mounted on wheels and a handle for manual pushing or pulling. It's commonly used for transporting various items and materials over short distances in a wide range of environments.",
    Applications:"Hand cart platform trolleys are utilized for various applications, including transporting goods within warehouses, distribution centers, and retail stores, assisting with restocking shelves and moving merchandise in supermarkets, supporting luggage and cargo transportation in airports and train stations, facilitating event setup and breakdown, and moving tools and materials in workshops, factories, and construction sites.",
    Color:"Red/Yellow/Blue",
    Capacity:"50-250 Kg",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"Mild Steel",
    Shape:"",
    Style:"",
},
 {
    Image:i17,
    BrandName:"MHE Movers",
    Name:"Heavy Duty Platform Trolley",
    Description:"A heavy-duty platform trolley is a robust material handling tool designed for transporting large and heavy items over short distances. It typically features a sturdy platform mounted on durable wheels and is constructed to withstand the weight and demands of heavy loads.",
    Applications:"Heavy-duty platform trolleys are extensively used across various industries for tasks such as transporting heavy pallets, machinery, and equipment in warehouses and distribution centers, moving bulky raw materials and finished products in manufacturing facilities, transporting construction materials and tools on construction sites, handling large and heavy items in retail stores, loading and unloading heavy cargo in logistics and transportation, and facilitating setup and breakdown of events and exhibitions.",
    Color:"Red/Yellow/Blue",
    Capacity:"1-2 Tons",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"Mild Steel",
    Shape:"Rectangle",
    Style:"",
},
 {
    Image:i18,
    BrandName:"MHE Movers",
    Name:"Wire Mesh Platform Trolley ",
    Description:"A wire mesh platform trolley is a material handling tool designed with a platform constructed of wire mesh, mounted on wheels for ease of movement. These trolleys are commonly used for transporting goods, materials, and items in various industrial and commercial settings.",
    Applications:"Wire mesh platform trolleys are widely used across industries for tasks such as transporting goods in warehouses and distribution centers, moving products in retail and supermarkets, carrying parts in manufacturing facilities, transporting crops on farms, moving supplies in hospitality and catering, and transporting tools and equipment in facilities management.",
    Color:"Red/Yellow/Blue",
    Capacity:"100-200kg",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"Steel",
    Shape:"Square",
    Style:"",
},
]

 const GoodsLift =[{
    Image:i19,
    BrandName:"MHE Movers",
    Name:"Cabin Type Goods Lift",
    Description:"A cabin type goods lift is a vertical transportation system designed for efficiently moving goods and materials between different floors of a building or facility. It consists of a fully enclosed cabin or platform that travels along a track or guide rails installed within a shaft or hoistway.",
    Applications:"Cabin type goods lifts are commonly used across industries for various purposes such as transporting palletized goods, boxes, and materials within warehouses and distribution centers; moving heavy machinery parts, equipment, and materials vertically within manufacturing facilities; transporting merchandise, supplies, and inventory between different levels of retail stores; lifting medical equipment, supplies, and patient care items between floors in hospitals and medical facilities; moving supplies, food, and beverages between kitchens, storage areas, and dining floors in hotels and restaurants; and transporting office supplies, documents, and equipment between floors in office buildings.",
    Color:"Red/Yellow/Blue",
    Capacity:"2.5 Tons",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"220v",
    Material:"Steel",
    Shape:"",
    Style:"",
},

{
    Image:i20,
    BrandName:"MHE Movers",
    Name:"Caged Goods Lift ",
    Description:"A caged goods lift is a vertical transportation system designed for safely moving goods and materials between different floors of a building or facility. It features a fully enclosed cage or compartment, often constructed with mesh or bars, to secure the items being transported during the lifting process.",
    Applications:"Caged goods lifts are commonly utilized for transporting heavy or bulky goods within warehouses, distribution centers, and manufacturing facilities, as well as for moving construction materials, tools, and equipment vertically on construction sites. In retail environments, they facilitate the efficient movement of goods and supplies in stores, supermarkets, and wholesale warehouses. Moreover, caged goods lifts play a vital role in transporting luggage, packages, and cargo in hotels, airports, and shipping terminals. They are also essential for transporting food items, supplies, and equipment between floors in restaurants, catering facilities, and commercial kitchens. Additionally, caged goods lifts aid in stock management and inventory control by efficiently transporting goods between different levels of storage facilities.",
    Color:"Red/Yellow/Blue",
    Capacity:"5-10 Tonskg",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"220-440v",
    Material:"Steel",
    Shape:"Square/Rectangle",
    Style:"",
},
{
    Image:i21,
    BrandName:"MHE Movers",
    Name:"Chain Pulley Goods Lift",
    Description:"A chain pulley goods lift is a type of vertical transportation system used for lifting and lowering goods and materials between different levels of a building or facility. It typically consists of a platform or cage attached to a system of chains and pulleys, which are powered by a motor to facilitate the movement of the load.",
    Applications:"Chain pulley goods lifts are commonly employed across industries for various purposes, including transporting heavy or bulky items within warehouses, distribution centers, and manufacturing facilities. They are also utilized in construction activities for lifting materials, tools, and equipment to different levels of a building or construction site. In logistics and transportation facilities, such as warehouses, docks, and distribution centers, they support loading and unloading operations. Additionally, chain pulley goods lifts assist with inventory management and stock replenishment in retail stores, supermarkets, and wholesale warehouses. They also enhance efficiency in agricultural operations by lifting and transporting crops, livestock feed, and farming equipment, and support maintenance and repair tasks in industrial settings by transporting machinery, spare parts, and tools between floors.",
    Color:"Red/Yellow/Blue",
    Capacity:"upto 2 Tons",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"Steel",
    Shape:"Square",
    Style:"",
},
{
    Image:i22,
    BrandName:"MHE Movers",
    Name:"Commercial Goods Lift ",
    Description:"A commercial goods lift is a type of vertical transportation system specifically designed for the efficient movement of goods and materials within commercial buildings or facilities. These lifts are robustly constructed to handle heavy loads and are equipped with safety features to ensure the secure transportation of goods between different floors.",
    Applications:"Commercial goods lifts are commonly used for transporting goods and materials within warehouses, distribution centers, and manufacturing facilities, as well as in retail stores, supermarkets, and wholesale warehouses for stock replenishment and inventory management. They also play a crucial role in logistics and transportation facilities, aiding in the loading and unloading of goods in warehouses, docks, and distribution centers. In addition, commercial goods lifts are utilized in construction projects for lifting construction materials, tools, and equipment to different levels of a building or construction site. Moreover, they facilitate efficient stock management and inventory control in retail environments by transporting goods between different levels of storage facilities. Furthermore, commercial goods lifts are essential in agricultural operations for lifting and transporting crops, livestock feed, and farming equipment, and they support maintenance and repair tasks in industrial settings by transporting machinery, spare parts, and tools between floors.",
    Color:"Red/Yellow/Blue",
    Capacity:"Upto 10 Tons",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"Steel",
    Shape:"Square",
    Style:"",
},
{
    Image:i23,
    BrandName:"MHE Movers",
    Name:"Hoist Goods Lift",
    Description:"A hoist goods lift is a type of vertical transportation system designed specifically for lifting and lowering goods and materials between different levels of a building or facility. It typically consists of a platform or cage attached to a hoist mechanism, which is powered by an electric motor or hydraulic system to facilitate the movement of the load.",
    Applications:"Hoist goods lifts are commonly used for transporting heavy or bulky items within warehouses, distribution centers, and manufacturing facilities, as well as in retail stores, supermarkets, and wholesale warehouses for stock replenishment and inventory management. They also play a crucial role in logistics and transportation facilities, aiding in the loading and unloading of goods in warehouses, docks, and distribution centers. In addition, hoist goods lifts are utilized in construction projects for lifting construction materials, tools, and equipment to different levels of a building or construction site. Moreover, they facilitate efficient stock management and inventory control in retail environments by transporting goods between different levels of storage facilities. Furthermore, hoist goods lifts are essential in agricultural operations for lifting and transporting crops, livestock feed, and farming equipment, and they support maintenance and repair tasks in industrial settings by transporting machinery, spare parts, and tools between floors.",
    Color:"Red/Yellow/Blue",
    Capacity:"3-4 Tons",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"Steel",
    Shape:"Square",
    Style:"",
},
{
    Image:i24,
    BrandName:"MHE Movers",
    Name:"Hydraulic Goods Lift",
    Description:"A hydraulic goods lift is a type of vertical transportation system designed for lifting and lowering goods and materials between different levels of a building or facility using hydraulic power. It typically consists of a platform or cage attached to a hydraulic system, which utilizes fluid pressure to raise and lower the load.",
    Applications:"Hydraulic goods lifts are commonly used for transporting heavy or bulky items within warehouses, distribution centers, and manufacturing facilities, as well as in retail stores, supermarkets, and wholesale warehouses for stock replenishment and inventory management. They also play a crucial role in logistics and transportation facilities, aiding in the loading and unloading of goods in warehouses, docks, and distribution centers. In addition, hydraulic goods lifts are utilized in construction projects for lifting construction materials, tools, and equipment to different levels of a building or construction site. Moreover, they facilitate efficient stock management and inventory control in retail environments by transporting goods between different levels of storage facilities. Furthermore, hydraulic goods lifts are essential in agricultural operations for lifting and transporting crops, livestock feed, and farming equipment, and they support maintenance and repair tasks in industrial settings by transporting machinery, spare parts, and tools between floors.",
    Color:"Red/Yellow/Blue",
    Capacity:"Upto 10 Tons",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"Steel",
    Shape:"Square",
    Style:"",
},
{
    Image:i25,
    BrandName:"MHE Movers",
    Name:"Winch Goods Lift",
    Description:"A winch goods lift is a type of vertical transportation system designed to lift and lower goods and materials between different levels of a building or facility using a winch mechanism. It typically consists of a platform or cage attached to a cable or rope, which is wound around a drum operated by a winch motor to raise and lower the load.",
    Applications:"Winch goods lifts are versatile and find various applications across industries. They are commonly used for moving heavy or bulky items within warehouses and distribution centers, lifting construction materials, tools, and equipment to different levels of a building or construction site, transporting goods, supplies, and inventory between different levels of retail stores, supermarkets, and wholesale warehouses, loading and unloading goods in logistics and transportation facilities, lifting and transporting crops, livestock feed, and farming equipment on farms and agricultural facilities, and transporting machinery, spare parts, and tools between floors in industrial settings.",
    Color:"Red/Yellow/Blue",
    Capacity:"100-200kg",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"Steel",
    Shape:"Square",
    Style:"",
},
{
    Image:i26,
    BrandName:"MHE Movers",
    Name:"Roap Drum Goods Lift  ",
    Description:"A rope drum goods lift is a type of vertical transportation system used for lifting and lowering goods and materials between different levels of a building or facility. It typically consists of a platform or cage attached to a cable or rope wound around a drum, which is powered by a motor to facilitate the movement of the load.",
    Applications:"Rope drum goods lifts find various applications across industries. They are commonly used for moving heavy or bulky items within warehouses and distribution centers, lifting construction materials, tools, and equipment to different levels of a building or construction site, transporting goods, supplies, and inventory between different levels of retail stores, supermarkets, and wholesale warehouses, loading and unloading goods in logistics and transportation facilities, lifting and transporting crops, livestock feed, and farming equipment on farms and agricultural facilities, and transporting machinery, spare parts, and tools between floors in industrial settings.",
    Color:"Red/Yellow/Blue",
    Capacity:"",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"Steel",
    Shape:"",
    Style:"",
},
{
    Image:i27,
    BrandName:"MHE Movers",
    Name:"Passenger Cum Goods Lift ",
    Description:"A passenger cum goods lift is a type of vertical transportation system designed to carry both passengers and goods or materials simultaneously between different levels of a building or facility. It typically features a cabin or platform that can accommodate both passengers and cargo, with appropriate safety features to ensure the safe transportation of both.",
    Applications:"Passenger cum goods lifts find various applications across industries. They are commonly used in buildings and facilities where there is a need to transport both passengers and goods, such as warehouses and distribution centers for moving both employees and goods between different levels of the facility, commercial buildings for transporting both customers and merchandise to different floors of retail stores, shopping malls, and office buildings, hotels for transporting guests along with their luggage between different floors, hospitals for transporting patients along with medical equipment and supplies between different levels of the hospital, residential buildings for transporting residents along with their belongings, groceries, or other items to different floors of apartment buildings or condominiums, and industrial facilities for transporting workers along with tools, equipment, or materials between different levels of manufacturing plants or production facilities.",
    Color:"Red/Yellow/Blue",
    Capacity:"",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"Steel",
    Shape:"",
    Style:"",
},
{
    Image:i28,
    BrandName:"MHE Movers",
    Name:"Motorized Goods Lift",
    Description:"A motorized goods lift is a type of vertical transportation system used for lifting and lowering goods and materials between different levels of a building or facility. It is equipped with an electric motor that powers the lift mechanism, allowing for efficient and controlled movement of heavy loads.",
    Applications:"Motorized goods lifts are commonly used for transporting heavy or bulky items within warehouses, distribution centers, manufacturing facilities, and retail stores. They facilitate the movement of goods between different floors, aiding in stock replenishment, inventory management, and order fulfillment processes. Additionally, motorized goods lifts play a crucial role in logistics and transportation operations, enabling the loading and unloading of goods in warehouses, docks, and distribution centers. They are also utilized in construction projects for lifting construction materials, tools, and equipment to different levels of a building or construction site. Moreover, motorized goods lifts support agricultural operations by lifting and transporting crops, livestock feed, and farming equipment on farms and agricultural facilities. Furthermore, they are used in industrial settings for transporting machinery, spare parts, and tools between floors, as well as in residential and commercial buildings for moving heavy items and equipment.",
    Color:"Red/Yellow/Blue",
    Capacity:"",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"Steel",
    Shape:"",
    Style:"",
}]

const ScissorLift =[{
    Image:i29,
    BrandName:"MHE Movers",
    Name:"Battery Operated Scissor Lift ",
    Description:"A battery-operated scissor lift is a type of mobile elevated work platform (MEWP) that operates using an electric battery as its power source. It features a scissor mechanism that raises and lowers a platform, providing vertical access to elevated work areas.",
    Applications:"A battery-operated scissor lift is commonly utilized across various industries for several applications, such as accessing elevated work areas during construction, maintenance, and repair tasks; picking, stocking, and retrieving goods in warehouses and distribution centers; performing routine maintenance, repairs, and inspections on equipment and infrastructure; conducting shelf stocking, signage installation, and maintenance tasks in retail stores and supermarkets; setting up stages, lighting, and decorations during events and exhibitions; pruning trees, trimming hedges, and accessing elevated areas in landscaping and outdoor maintenance tasks; and conducting maintenance, repair, and inspection tasks on aircraft and aerospace equipment.",
    Color:"Red/Yellow/Blue",
    Capacity:"0.3 Tons",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"Mild Steel",
    Shape:"",
    Style:"",
},

{
    Image:i30,
    BrandName:"MHE Movers",
    Name:"High Rise Scissor Lift ",
    Description:"High Rise Scissor Lift",
    Applications:"A high-rise scissor lift is commonly used across various industries for accessing elevated areas at greater heights. It finds applications in construction for tasks such as painting, welding, and installation work on tall buildings and structures. Additionally, it is utilized in maintenance and facilities management for conducting inspections, repairs, and maintenance tasks at elevated areas in commercial buildings, factories, and industrial facilities. In window cleaning, high-rise scissor lifts provide access to clean and maintain high-rise windows and facades in commercial buildings and skyscrapers. They are also employed in film and television production to provide elevated platforms for filming and lighting equipment during movie and television productions. In event setup and decoration, high-rise scissor lifts are used for setting up stages, lighting, and decorations for concerts, shows, and events held in large venues. Furthermore, they find applications in tree trimming and landscaping for reaching tall trees and structures for pruning, trimming, and maintenance in parks, gardens, and outdoor spaces. Lastly, high-rise scissor lifts are utilized in utilities and infrastructure for conducting maintenance and repair tasks on power lines, telecommunication towers, and other infrastructure at elevated heights.",
    Color:"Red/Yellow/Blue",
    Capacity:"250kg",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"Steel",
    Shape:"",
    Style:"",
},

{
    Image:i31,
    BrandName:"MHE Movers",
    Name:"Hydraulic Scissor Lift ",
    Description:"A hydraulic scissor lift is a type of mobile elevated work platform (MEWP) that operates using a hydraulic system to raise and lower a platform vertically. It consists of a scissor mechanism, powered by hydraulic cylinders, which enables the lift to extend and retract, providing access to elevated work areas.",
    Applications:"A hydraulic scissor lift is commonly utilized across various industries for accessing elevated work areas. It finds applications in construction for tasks such as painting, welding, and installation work on buildings and structures. Additionally, it is used in maintenance and facilities management for conducting inspections, repairs, and maintenance tasks at elevated areas in commercial buildings, factories, and industrial facilities. In warehousing and logistics, hydraulic scissor lifts facilitate picking, stocking, and retrieving goods stored on high shelves in warehouses and distribution centers. They are also employed in retail and commercial settings for shelf stocking, signage installation, and maintenance tasks in stores, supermarkets, and malls. Furthermore, hydraulic scissor lifts find applications in events and entertainment for setting up stages, lighting, and decorations during events, concerts, and exhibitions in large venues. In landscaping and outdoor maintenance, they are used for pruning trees, trimming hedges, and accessing elevated areas in parks, gardens, and outdoor facilities. Lastly, hydraulic scissor lifts are utilized in automotive and transportation industries for accessing vehicles for maintenance, repair, and inspection tasks in workshops and garages.",
    Color:"Red/Yellow/Blue",
    Capacity:"1 Tons",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"Steel",
    Shape:"",
    Style:"",
},

{
    Image:i32,
    BrandName:"MHE Movers",
    Name:"Pneumatic Scissor Lift",
    Description:"A pneumatic scissor lift is a type of mobile elevated work platform (MEWP) that operates using compressed air or gas to raise and lower a platform vertically. It features a scissor mechanism similar to hydraulic lifts but utilizes pneumatic power for movement.",
    Applications:"Pneumatic scissor lifts are essential for vertical access tasks in construction, maintenance, warehousing, retail, events, landscaping, and automotive industries, providing efficient solutions for elevated work.",
    Color:"Red/Yellow/Blue",
    Capacity:"",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"Steel",
    Shape:"",
    Style:"",
},

{
    Image:i33,
    BrandName:"MHE Movers",
    Name:"Battery Operated Scissor Lift ",
    Description:"",
    Applications:"",
    Color:"Red/Yellow/Blue",
    Capacity:"350kg",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"Steel",
    Shape:"",
    Style:"",
},

{
    Image:i34,
    BrandName:"MHE Movers",
    Name:"Self Propelled Scissor Lift  ",
    Description:"A self-propelled scissor lift is a type of mobile elevated work platform (MEWP) that features its own onboard propulsion system, typically powered by electric batteries or diesel engines. This allows the lift to move horizontally as well as vertically, providing increased flexibility and maneuverability in accessing elevated work areas.",
    Applications:"Self-propelled scissor lifts are versatile tools utilized across industries for accessing elevated work areas, including construction, maintenance, warehousing, retail, events, landscaping, and automotive tasks.",
    Color:"Red/Yellow/Blue",
    Capacity:"500kg",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"Steel",
    Shape:"",
    Style:"",
},

{
    Image:i35,
    BrandName:"MHE Movers",
    Name:"Table scissor lift ",
    Description:"A table scissor lift is a lifting mechanism designed to adjust the height of a table or platform vertically. It finds applications in industrial workstations, material handling, retail displays, automotive repair, medical equipment, laboratories, and ergonomic workspaces.",
    Applications:"A table scissor lift is a type of lifting mechanism designed to raise and lower a table or platform vertically. It typically employs a scissor-like mechanism that extends and retracts to adjust the height of the table or platform.",
    Color:"Red/Yellow/Blue",
    Capacity:"",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"Steel",
    Shape:"",
    Style:"",
},

{
    Image:i36,
    BrandName:"MHE Movers",
    Name:"Trolley Scissor Lift",
    Description:"A trolley scissor lift is a type of lifting equipment that combines the functionality of a trolley and a scissor lift. It typically consists of a platform or table mounted on a scissor mechanism, which is then attached to a trolley base with wheels for easy mobility.",
    Applications:"A trolley scissor lift combines the features of a trolley and a scissor lift, featuring a platform mounted on a scissor mechanism attached to a trolley base with wheels. It is used across various industries for material handling, warehouse operations, maintenance, retail, events, construction, and landscaping tasks.",
    Color:"Red/Yellow/Blue",
    Capacity:"",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"Steel",
    Shape:"",
    Style:"",
}]


 
 const DockLevellers =[{
    Image:i37,
    BrandName:"MHE Movers",
    Name:"Mobile Dock Leveller",
    Description:"A mobile dock leveler is a portable loading dock equipment used to bridge the gap between the dock and the trailer bed, ensuring smooth and safe transfer of goods. It can be easily moved to different locations within a facility and is typically operated using hydraulic or manual mechanisms.",
    Applications:"A mobile dock leveler is a portable loading dock equipment used to bridge the gap between the dock and the trailer bed, ensuring smooth and safe transfer of goods. It can be easily moved to different locations within a facility and is typically operated using hydraulic or manual mechanisms. Mobile dock levelers find applications in warehousing, logistics, manufacturing, retail, construction, and events for efficient loading and unloading operations.",
    Color:"Red/Yellow/Blue",
    Capacity:"8/10/12 Tons",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"Steel",
    Shape:"",
    Style:"",
},
 {
    Image:i38,
    BrandName:"MHE Movers",
    Name:"Ramp Mobile Dock Leveller",
    Description:"A ramp mobile dock leveler is a portable loading dock solution equipped with a ramp to bridge the gap between the loading dock and the trailer bed. It offers versatility in relocation within a facility and is typically operated using hydraulic or manual mechanisms. Widely used across various industries, it facilitates seamless loading and unloading operations.r",
    Applications:"amp mobile dock levelers are widely applied across industries for versatile loading and unloading needs. They are commonly used in warehousing, logistics, manufacturing, retail, construction, and events for efficient cargo transfer between trucks and loading docks, ensuring smooth operations and streamlined material handling processes.",
    Color:"Red/Yellow/Blue",
    Capacity:"",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"Steel",
    Shape:"",
    Style:"",
}]

const HydraulicFloorCrane = [{
    Image:i39,
    BrandName:"MHE Movers",
    Name:"Hydraulic Floor Crane",
    Description:"A hydraulic floor crane is a lifting device that utilizes hydraulic power to lift and transport heavy loads horizontally and vertically. It typically consists of a stable base, a hydraulic lifting mechanism, and a boom with a hook or other lifting attachment.",
    Applications:"Hydraulic floor cranes find extensive use in manufacturing facilities for moving heavy machinery and materials, aiding in production processes and assembly tasks. In warehousing and logistics, they facilitate the efficient loading and unloading of goods from trucks and containers, ensuring smooth operations in distribution centers. Construction sites utilize hydraulic floor cranes for lifting and positioning construction materials like steel beams and concrete panels, enhancing construction efficiency and safety. Maintenance and repair tasks benefit from hydraulic floor cranes, which assist in lifting heavy parts and machinery for servicing and repairs, reducing manual handling risks and improving workplace safety. In shipping and transportation, these cranes are integral to cargo handling operations in ports and freight terminals, streamlining the loading and unloading of ships and trains. Automotive repair shops and garages rely on hydraulic floor cranes for installing and removing engines, transmissions, and other heavy components, facilitating efficient vehicle maintenance. Aerospace and aviation industries utilize these cranes for moving aircraft parts and components during assembly and maintenance operations in aerospace facilities. Moreover, hydraulic floor cranes play a vital role in utilities sectors by assisting in the installation and maintenance of utility infrastructure such as electrical transformers and telecommunications equipment, ensuring reliable service provision and infrastructure upkeep. Overall, hydraulic floor cranes offer versatile lifting solutions that enhance productivity and safety across a wide range of industries and applications.",
    Color:"Red/Yellow/Blue",
    Capacity:"",
    LiftingCapacity:"",
    Certification:"CE Certified",
    DrivenType:"",
    Warranty:"1 Year",
    ForkLenght:"",
    ForkWidth:"",
    RatedLoad:"",
    Type:"",
    BodyMaterail:"",
    Power:"",
    Voltage:"",
    Material:"Steel",
    Shape:"",
    Style:"",
}]

export let product =[
    {categoryItems:IndustrialStakers,
    categorieName:"Industrial Stackers"},
    {categoryItems:palletTrucks,
        categorieName:"Pallet Trucks"},
    {categoryItems:PlatformTrolleys,
        categorieName:"Pallet Trolleys"},
    {categoryItems:GoodsLift,
        categorieName:"Goods Lifts"},
    {categoryItems:ScissorLift,
        categorieName:"Scissor Lifts"},
    {categoryItems:DockLevellers,
        categorieName:"Dock Levellers"},
    {categoryItems:HydraulicFloorCrane,
        categorieName:"Hydraulic Floor Crane"},
]
