import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import img1 from './products/Product images/BANNER 1.png'
import img2 from './products/Product images/BANNER 2.png'
import img3 from './products/Product images/BANNER 3.png'
import './caro.css'

function CarouselPage() {
  return (
    <div>
    <Carousel>
    <Carousel.Item interval={1000}>
      <img
        className="d-block w-100 caro-height"
        src={img1}
        alt="First slide"
      />
      <Carousel.Caption>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item interval={900}>
      <img
        className="d-block w-100 caro-height"
        src={img2}
        alt="Second slide"
      />

      <Carousel.Caption>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item interval={900}>
      <img
        className="d-block w-100 caro-height"
        src={img3}
        alt="Third slide"
      />

      <Carousel.Caption>
      </Carousel.Caption>
    </Carousel.Item>
  </Carousel>
  </div>
  )
}

export default CarouselPage