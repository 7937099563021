import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import ModalPage from "../ModalPage";

export default function DeatilsPage() {
  const location = useLocation();
  const [isOpenModal, setisOpenModal] = useState(false);
  function onCallClick() {
    setisOpenModal(true);
  }
  function onWatsAppClick() {
    //  setisClickOnWatsApp(!isClickOnWatsApp)
    window.open("https://api.whatsapp.com/send?phone=918147457499", "_blank");
  }
  function modalClose() {
    setisOpenModal(false);
  }
  function onModalSumbmit() {
    setisOpenModal(false);
    alert("summitted");
  }
  return (
    <>
      <div className="main-container">
        {isOpenModal && (
          <ModalPage
            isOpenModal={isOpenModal}
            modalClose={modalClose}
            onModalSumbmit={onModalSumbmit}
          />
        )}
        <div className="product-page">
          <div className="left-part">
            <img src={location.state.item.Image} alt=""></img>
            <div class="banner-btn">
              <button onClick={onWatsAppClick}>
                <span></span>
                <i class="fa fa-whatsapp"></i> Chat With Us
              </button>
              <button className="saf-button" onClick={onCallClick}>
                <span></span>
                <i class="fa fa-envelope"></i> Send Enquiry
              </button>
            </div>
          </div>
          <div className="right-part">
            <h4 className="p-name">{location.state.item.Name}</h4>
            <p className="">Specification</p>
            <table>
              <tbody>
                {location.state.item.Capacity && (
                  <tr>
                    <td className="spec-name">Capacity</td>
                    <td className="spec-value">
                      {location.state.item.Capacity}
                    </td>
                  </tr>
                )}
                {location.state.item.Type && (
                  <tr>
                    <td className="spec-name">Type</td>
                    <td className="spec-value">{location.state.item.Type}</td>
                  </tr>
                )}
                {location.state.item.LiftingCapacity && (
                  <tr>
                    <td className="spec-name">LiftingCapacity</td>
                    <td className="spec-value">
                      {location.state.item.LiftingCapacity}
                    </td>
                  </tr>
                )}
                {location.state.item.Certification && (
                  <tr>
                    <td className="spec-name">Certification</td>
                    <td className="spec-value">
                      {location.state.item.Certification}
                    </td>
                  </tr>
                )}
                {location.state.item.Warranty && (
                  <tr>
                    <td className="spec-name">Warranty</td>
                    <td className="spec-value">
                      {location.state.item.Warranty}
                    </td>
                  </tr>
                )}
                {location.state.item.ForkLenght && (
                  <tr>
                    <td className="spec-name">ForkLenght</td>
                    <td className="spec-value">
                      {location.state.item.ForkLenght}
                    </td>
                  </tr>
                )}
                {location.state.item.ForkWidth && (
                  <tr>
                    <td className="spec-name">ForkWidth</td>
                    <td className="spec-value">
                      {location.state.item.ForkWidth}
                    </td>
                  </tr>
                )}
                {location.state.item.RatedLoad && (
                  <tr>
                    <td className="spec-name">RatedLoad</td>
                    <td className="spec-value">
                      {location.state.item.RatedLoad}
                    </td>
                  </tr>
                )}
                {location.state.item.DrivenType && (
                  <tr>
                    <td className="spec-name">DrivenType</td>
                    <td className="spec-value">
                      {location.state.item.DrivenType}
                    </td>
                  </tr>
                )}
                {location.state.item.BodyMaterail && (
                  <tr>
                    <td className="spec-name">BodyMaterail</td>
                    <td className="spec-value">
                      {location.state.item.BodyMaterail}
                    </td>
                  </tr>
                )}
                {location.state.item.Power && (
                  <tr>
                    <td className="spec-name">Power</td>
                    <td className="spec-value">{location.state.item.Power}</td>
                  </tr>
                )}{" "}
                {location.state.item.Shape && (
                  <tr>
                    <td className="spec-name">Shape</td>
                    <td className="spec-value">{location.state.item.Shape}</td>
                  </tr>
                )}
                {location.state.item.Material && (
                  <tr>
                    <td className="spec-name">Material</td>
                    <td className="spec-value">
                      {location.state.item.Material}
                    </td>
                  </tr>
                )}
                {location.state.item.Style && (
                  <tr>
                    <td className="spec-name">Style</td>
                    <td className="spec-value">{location.state.item.Style}</td>
                  </tr>
                )}
                {location.state.item.Description && (
                  <tr>
                    <td className="spec-name">Description</td>
                    <td className="spec-value">
                      {location.state.item.Description}
                    </td>
                  </tr>
                )}
                {location.state.item.Applications && (
                  <tr>
                    <td className="spec-name">Applications</td>
                    <td className="spec-value">
                      {location.state.item.Applications}
                    </td>
                  </tr>
                )}
                <tr>
                    <td className="spec-name">Location</td>
                    <td className="spec-value">
                    India(Karnataka)
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
