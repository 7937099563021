import React from 'react';
import maintenance from "./Product images/f2.jpg"
import service from "./Product images/Forklift_Repair_Services.jpg"
import { useNavigate } from 'react-router-dom'

export default function Produts() {
    let history = useNavigate();
    function handleclick(){
      history("/services")
    }
    
  return (
    <>
    <div className='serv-main'>
        {/* {ProductsList.map((categories,index)=>{
            return(
                <Card categories={categories} index={index} handleclick={handleclick}></Card>
            )
        })} */}
        <div className='serv-card'>
            <h3>ForkLift Maintenance Services</h3>
            <div className='service-img'>
            <img src={maintenance} alt=''></img>
            </div>
            <p>At MHE Movers, we understand the importance of keeping your forklifts operating at peak performance. With our comprehensive forklift maintenance services, we ensure that your equipment stays reliable, efficient, and safe for operation.</p>
            <div class="banner-btn">
              
            <button onClick={handleclick}><span></span>See More</button>
        </div>

        </div>
        <div className='serv-card'>
        <h3>ForkLift Repairing Services</h3>
        <div className='service-img'>
            <img src={service} alt=''></img>
            </div>
            <p>At MHE Movers, we specialize in providing top-notch pallet stacker repair services to keep your equipment running smoothly and efficiently. As a trusted provider in the industry, we understand the critical role that pallet stackers play in your operations, and we're dedicated to ensuring that they remain in optimal condition.</p>
            <div class="banner-btn">
            <button onClick={handleclick}><span></span>See More</button>
        </div>
        </div>

    </div>

    <div></div>
    </>
  )
}