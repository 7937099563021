import React from 'react'
import ContactForm from "./ContactForm"
import Map from './Map'

export default function Contactus() {
  return (
    <>
    <div className='cont-main'>
      <div className='left-cont'>
       <p><i className='fa fa-phone'></i> : +91 8147457499 / +91 8884848935</p> 
<p><i className='fa fa-envelope'></i> : Info@mhemovers.com </p>  
 <p><i className='fa fa-home'></i> : Thigalarapalya Main Rd, Peenya 2nd Stage, Ragavendra Industrial Estate, Bengaluru, Karnataka, Bharath - 560058</p>

 <div class="social">
    <a href="https://api.whatsapp.com/send?phone=918147457499" rel="noreferrer" target="_blank"> <i class="fa fa-whatsapp"></i></a>
   <a href="https://youtube.com/@MHEMovers" rel="noreferrer" target="_blank"> <i class="fa fa-youtube-play"></i></a>
    <a href="https://www.facebook.com/profile.php?id=61556215449835" rel="noreferrer" target="_blank"> <i class="fa fa-facebook"></i></a> 
   <a href="https://www.instagram.com/mhemover/?fbclid=IwAR2cxl-70bZe6-ZbFqNv8fVI5tAfGNe0Rd2I-QGLCTsDTM-bfk-IXCrU8_I" rel="noreferrer" target="_blank"> <i class="fa fa-instagram"></i></a> 

</div>
      </div>
    <div className='Contact-form p-2'>
      <h4>Contact Us</h4>
      <ContactForm/>
      </div>
    </div>
      <Map/>
      </>
  )
}
